import { graphql } from 'gatsby'
import React from 'react'
import { IResource } from '../../interfaces/resourceInterfaces'
import PureResource from './PureResource'

interface IResourceProps {
  data: {
    strapiResources: IResource
  }
}

export const query = graphql`
  query ResourceQuery($name: String!) {
    strapiResources(name: { eq: $name }) {
      name
      description
      rests {
        name
        version
        data
        id
      }
      grpcs {
        name
        version
        data
        id
      }
      webhooks {
        name
        version
        data
        id
      }
      graphqls {
        name
        version
        data
        id
      }
    }
  }
`

const Resource: React.FC<IResourceProps> = ({ data }): JSX.Element => {
  const resource = data.strapiResources
  return <PureResource resource={resource} />
}

export default Resource
