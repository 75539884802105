import { Badge as MomentumBadge } from '@momentum-ui/react'
import React from 'react'

interface IBadgeProps {
  type: string
}

const Badge: React.FC<IBadgeProps> = ({ type }): JSX.Element | null => {
  const badge = (type: string): JSX.Element | null => {
    const options: { [key: string]: string } = {
      GET: 'get-badge-bg-color',
      POST: 'post-badge-bg-color',
      PUT: 'put-badge-bg-color',
      PATCH: 'patch-badge-bg-color',
      DELETE: 'delete-badge-bg-color',
      EVENT: 'event-badge-bg-color',
    }
    if (options[type]) {
      return (
        <MomentumBadge className={`badge ${options[type]}`}>
          {type}
        </MomentumBadge>
      )
    } else {
      return null
    }
  }

  return badge(type)
}

export default Badge
