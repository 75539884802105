import { Tab, TabContent, TabList, TabPane, Tabs } from '@momentum-ui/react'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useSelector } from 'react-redux'
import ApiListItem from '../../components/ApiListItem'
import Seo from '../../components/Seo'
import TableOfContents from '../../components/TableOfContents'
import { PAGE_VIEWED } from '../../constants/metrics'
import { IResource } from '../../interfaces/resourceInterfaces'
import DocumentationLayout from '../../layouts/DocumentationLayout'
import MetricsService from '../../services/metricsService'
import { retrievePermittedData } from '../../state/auth'
import { AppState } from '../../state/store'
import { makeKebabCase } from '../../utils/general.utils'
import { linkRenderer } from '../../utils/markdown.utils'
interface IPureResourceProps {
  resource: IResource
}

const PureResource: React.FC<IPureResourceProps> = ({
  resource,
}): JSX.Element => {
  const permittedData = useSelector((state: AppState) =>
    retrievePermittedData(state)
  )

  const metricsService = MetricsService.getInstance()
  metricsService.track(PAGE_VIEWED, {
    pageName: resource.name,
  })

  const apiTypeMap: any = {
    rests: {},
    webhooks: {},
    graphqls: {},
    grpcs: {},
  }
  if (Object.keys(permittedData).length) {
    const resourceTypes = ['rests', 'grpcs', 'webhooks', 'graphqls'] as const
    resourceTypes.forEach((type) => {
      resource[type] &&
        (resource[type] = (resource[type] as Array<any>)
          // filter out feature gated data
          .filter((api: any) => {
            return permittedData[type].find(
              ({ strapiId }: { [key: string]: number }) =>
                strapiId === Number(api.id)
            )
          })
          // create map of unique apis per api type w/latest versions of apis
          .map((permittedApi: any) => {
            if (!apiTypeMap[type][permittedApi.name]) {
              apiTypeMap[type][permittedApi.name] = permittedApi
            } else {
              if (
                permittedApi.version >
                apiTypeMap[type][permittedApi.name].version
              ) {
                apiTypeMap[type][permittedApi.name] = permittedApi
              }
            }
            return permittedApi
          })
          // filter out apis that arent on the map of unique apis
          .filter((permmittedApi_) => {
            return (
              apiTypeMap[type][permmittedApi_.name].version ===
              permmittedApi_.version
            )
          })
          // sort apis by endpoint when it exists
          .sort((a: any, b: any) => {
            if (type === 'rests' || type === 'graphqls') {
              return a.data.endpoint?.localeCompare(b.data.endpoint)
            }
            return a.name.localeCompare(b.name)
          }))
    })
  }

  const generateListContainer = (type: keyof IResource): JSX.Element => {
    return (
      <ul data-cy="apiContainer" className="api-container">
        {(resource[type] as Array<any>).map((apiItem, i) => {
          return (
            <ApiListItem
              key={i}
              name={apiItem.name}
              badgeType={apiItem.data.httpVerb || 'event'}
              endpoint={apiItem.data.endpoint || apiItem.data.eventType}
              description={apiItem.data.summary || apiItem.data.description}
              href={`v${apiItem.version}/${makeKebabCase(apiItem.name)}`}
            />
          )
        })}
      </ul>
    )
  }

  const generatedToc: Array<any> = []

  const getApisWithOrWithoutTabs = (): JSX.Element | undefined => {
    const tabs: JSX.Element[] = []
    const tabPanes: JSX.Element[] = []

    const displayableApiTypes = [
      ['rests', 'REST APIs'],
      ['grpcs', 'gRPC APIs'],
      ['webhooks', 'Webhooks'],
      ['graphqls', 'GraphQL APIs'],
    ].filter(([type, title]) => {
      const lengthOfApiItems = resource[type as keyof IResource]
        ? resource[type as keyof IResource].length
        : 0
      if (lengthOfApiItems > 0) {
        generatedToc.push(
          {
            label: title,
            level: 2,
          },
          ...(resource[type as keyof IResource] as Array<any>).map(
            (apiItem: any) => {
              return {
                href: `v${apiItem.version}/${makeKebabCase(apiItem.name)}`,
                label: apiItem.name,
                level: 3,
              }
            }
          )
        )
        tabs.push(
          <Tab heading={`${title} (${lengthOfApiItems})`} key={tabs.length} />
        )
        tabPanes.push(
          <TabPane key={tabPanes.length}>
            {generateListContainer(type as keyof IResource)}
          </TabPane>
        )
        return true
      }
      return false
    })

    if (tabs.length > 1) {
      return (
        <Tabs tabType="pills" className="resource-tabs">
          <TabList>{[...tabs]}</TabList>
          <TabContent>{[...tabPanes]}</TabContent>
        </Tabs>
      )
    } else if (tabs.length === 1) {
      return generateListContainer(displayableApiTypes[0][0] as keyof IResource)
    }
  }

  return (
    <DocumentationLayout>
      <Seo title={resource.name} description={resource.description} />
      <article
        className={
          generatedToc ? `resource-section with-toc` : 'resource-section'
        }
      >
        <section>
          <h1>{resource.name}</h1>
          <ReactMarkdown
            source={resource.description}
            className="description"
            renderers={linkRenderer}
          />
          <section className="api-list-section">
            {getApisWithOrWithoutTabs()}
          </section>
        </section>
        <section className="side-panel">
          <TableOfContents
            tableOfContentsList={generatedToc}
            disableScrollObserver
          />
        </section>
      </article>
    </DocumentationLayout>
  )
}

export default PureResource
