import React from 'react'
import Badge from '../Badge'

interface IApiRouteProps {
  badgeType: string
  endpoint: string
  className?: string
}

const ApiRoute: React.FC<IApiRouteProps> = ({
  badgeType,
  endpoint,
  className,
}) => {
  return (
    <>
      <Badge type={badgeType.toUpperCase()} />
      <span className={className}>{endpoint}</span>
    </>
  )
}

export default ApiRoute
